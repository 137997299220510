import React, {createRef, useState} from 'react';
import {NavLink} from 'react-router-dom';

import Lightbox from '../components/lightbox';
import Watermark from '../components/watermark';
import {Translation, useTranslation} from 'react-i18next';
import ServiceComponent from "../components/service/service";

const DEMO = (process.env.REACT_APP_MODE === 'demo');
const ACTION_REDIRECT = 'redirect';
const ACTION_SELECT = 'select';
const TYPE_BANKING = 'banking';
const TYPE_ACCOUNTANCY = 'accountancy';
const TYPE_SOCIAL = 'social';

export {ACTION_REDIRECT, ACTION_SELECT, TYPE_BANKING, TYPE_ACCOUNTANCY, TYPE_SOCIAL};
export default function ServicesPage(props) {
  // States
  const [lightbox, setLightbox] = useState(null);
  const [page, setPage] = useState('services');
  const [servicesAuthorized, setServicesAuthorized] = useState(false);
  const [selectedServices, setSelectedServices] = useState({});

  // i18n
  const {t} = useTranslation();

  function openLightbox(title, text) {
    setLightbox({title: title, text: text});
  }

  function onServiceSelect(id, link, type, action) {
    if (action === ACTION_REDIRECT) {
      if (page === link) {
        return;
      }

      if (link === '?' || link.length === 0) {
        setPage('services');

        return;
      }

      setPage(link);
    } else if (action === ACTION_SELECT) {
      if (selectedServices[type] === id) {
        delete selectedServices[type];
      } else {
        selectedServices[type] = id;
      }

      setSelectedServices({...selectedServices});
    }
  }

  function renderServices() {
    let services = [
      {
        id: 'a',
        name: 'Banking',
        link: 'banking',
        info: 'Share your Bank profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_BANKING,
        action: ACTION_REDIRECT,
      },
      {
        id: 'b',
        name: 'Accountancy',
        link: 'accountancy',
        info: 'Share your Facebook profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_REDIRECT,
      },
      {
        id: 'c',
        name: 'LinkedIn',
        link: '?',
        info: 'Share your LinkedIn profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_SOCIAL,
        action: ACTION_SELECT,
      },
      {
        id: 'd',
        name: 'Twitter',
        link: '?',
        info: 'Share your Twitter profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_SOCIAL,
        action: ACTION_SELECT,
      },
    ];

    const accountancy = [
      {
        id: 'a',
        name: 'Microsoft Dynamics',
        link: '?',
        info: 'Share your Bank profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_SELECT,
      },
      {
        id: 'b',
        name: 'SAP ERP',
        link: '?',
        info: 'Share your Facebook profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_SELECT,
      },
      {
        id: 'c',
        name: 'Zoho',
        link: '?',
        info: 'Share your LinkedIn profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_SELECT,
      },
      {
        id: 'd',
        name: 'Zoho',
        link: '?',
        info: 'Share your LinkedIn profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_SELECT,
      },
      {
        id: 'e',
        name: 'Quick Books',
        link: '?',
        info: 'Share your LinkedIn profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_SELECT,
      },
      {
        id: 'f',
        name: 'Xero',
        link: '?',
        info: 'Share your LinkedIn profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_ACCOUNTANCY,
        action: ACTION_SELECT,
      },
      {
        id: 'g',
        name: 'Back to services',
        link: '?',
        info: '',
        type: '',
        action: ACTION_REDIRECT,
      },
    ];

    const banking = [
      {
        id: 'a',
        name: 'Direct ID',
        link: '?',
        info: 'Share your Twitter profile data so we understand your interests, attitudes and opinions better.',
        type: TYPE_BANKING,
        action: ACTION_SELECT,
      },
      {
        id: 'b',
        name: 'Back to services',
        link: '?',
        info: '',
        type: '',
        action: ACTION_REDIRECT,
      },
    ];

    let servicesComponent = services.map((service) => {
      return (
        <ServiceComponent
          selectedServices={selectedServices}
          service={service}
          key={service.id}
          onServiceSelect={onServiceSelect.bind(this)}
        />
      );
    });


    if (page === 'banking') {
      servicesComponent = banking.map((service) => {
        return (
          <ServiceComponent
            selectedServices={selectedServices}
            service={service}
            key={service.id}
            onServiceSelect={onServiceSelect.bind(this)}
          />
        );
      });
    }

    if (page === 'accountancy') {
      servicesComponent = accountancy.map((service) => {
        return (
          <ServiceComponent
            selectedServices={selectedServices}
            service={service}
            key={service.id}
            onServiceSelect={onServiceSelect.bind(this)}
          />
        );
      });
    }

    return (
      <form className="step__form">
        {servicesComponent}
      </form>
    );
  }

  return (
    <div className="page template-primary">
      <div className="page__background">
        <Watermark/>
      </div>
      <div className="page__container">
        <section className="page__header">
          <h1 className="page__title">{t('services.share_title')}</h1>
          <p className="page__text">{t('services.share_intro')}</p>
        </section>
        <section className="page__content">
          <div className="services">
            <div className="services__services">
              {renderServices()}
            </div>
            <div className="services__privacy">
              <button className="services__privacy-button" onClick={() => {
                openLightbox(t('services.privacy_title'), t('services.privacy_text'))
              }}>
                {t('services.privacy_title')} <i className="icon-chevron-down"/>
              </button>
            </div>
          </div>
        </section>
        <section className="page__footer">
          {Object.keys(selectedServices).length > 0 ? <button className="step__submit-button">Login services</button> : ''}
          {
            servicesAuthorized ?
              <NavLink className="page__next-button" to="/questionnaire">{t('interface.start_btn')}</NavLink> :
              <NavLink className="page__link" to="/questionnaire">{t('services.no_sharing')}</NavLink>
          }
        </section>
      </div>
      {
        !lightbox ||
        <Lightbox
          title={lightbox.title}
          text={lightbox.text}
          onClose={() => {
            setLightbox(null)
          }}
        />
      }
    </div>
  );
}