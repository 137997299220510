import React, { Component } from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";

import IntroPage from "../routes/intro";
import QuestionnairePage from "../routes/questionnaire";
import HostedCustomerRedirect from "../routes/HostedCustomerRedirect";
import CompletePage from "../routes/complete";
import ErrorPage from "../routes/error";
import ServicesPage from "../routes/services";
import Geolocation from '../routes/geolocation';
import BankingPage from "../routes/banking";

export default class Router extends Component {

  render() {

    return(
      <HashRouter>
        <Switch>
          <Route path="/" render={(props) => (<IntroPage {...props} {...this.props} />)} exact />
          <Route path="/banking" render={(props) => (<BankingPage {...props} {...this.props} />)} exact />
          <Route path="/services" render={(props) => (<ServicesPage {...props} {...this.props} />)} exact />
          <Route path="/questionnaire" render={(props) => (<QuestionnairePage {...props} {...this.props} />)} exact />
          <Route path="/geolocation" render={(props) => (<Geolocation {...props} {...this.props} />)} exact />
          <Route path="/complete" render={(props) => (<CompletePage {...props} {...this.props} />)} exact />

          <Route path="/preview/page" render={(props) => (<IntroPage {...props} {...this.props} />)} exact />
          <Route path="/preview/question" render={(props) => (<QuestionnairePage {...props} {...this.props} />)} exact />

          <Route path="/q/:slug/:applicantId" render={() => (<HostedCustomerRedirect />)}  />

          <Route component={ErrorPage} />
        </Switch>
      </HashRouter>
    );
  }
}