import React, { Component } from "react";
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import Swiper from 'react-id-swiper/lib/custom';
import { Translation } from 'react-i18next';
import Watermark from '../components/watermark';
import PropTypes from "prop-types";
import ReactResizeDetector from 'react-resize-detector';
import ReactMarkdown from 'react-markdown';
import {notifyParentOfNewHeight} from '../utils/iframe';
import BehaviourTracking from '../utils/behaviourTracking';

class IntroPage extends Component {

  static propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })).isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    options: PropTypes.shape({
      skipIntro: PropTypes.bool,
      ask_geolocation: PropTypes.bool,
    }),
    tracker: PropTypes.instanceOf(BehaviourTracking).isRequired,
  };

  renderSlides() {
    return this.props.content.map( (slide, i) => {

      return (
        <div className="slide" key={i}>
          <div className="slide__content">
            <h1 className="page__title">{slide.title}</h1>
            <div className="page__text"><ReactMarkdown source={slide.text} /></div>
          </div>
        </div>
      );
    });
  }

  componentDidMount() {
    // solution for render the swiper again. This is needed!
    if (this.idSwiper) {
      this.idSwiper.swiper.update();
    }
    notifyParentOfNewHeight();
  }

  render() {
    if (this.props.options && this.props.options.skipIntro) {
      return <Redirect to='/banking' />
    }

    let swiperOptions = {};
    // Only add pagination if there is more than one page.
    if (this.props.content.length > 1) {
      swiperOptions = {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      };
    }

    return (
      <Translation>
        { (t, { i18n }) =>
          <div className="page template-intro">
            <div className="page__background">
              <Watermark />
            </div>
            <div className="page__container">
              <section className="page__header" />
              <section className="page__content">
                <div className="intro">
                  <div className="intro__content">
                    <ReactResizeDetector handleWidth onResize={() => { if (this.idSwiper) {this.idSwiper.swiper.update()} }} />
                    <Swiper {...swiperOptions} ref={ (swiper) => this.idSwiper = swiper }>
                      { this.renderSlides() }
                    </Swiper>
                  </div>
                </div>
              </section>
              <section className="page__footer">
                <NavLink onClick={() => {this.props.tracker.report('intro')}} className="page__next-button" to="/banking">{ t('interface.start_btn') }</NavLink>
              </section>
            </div>
          </div>
        }
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {

  return {
    content: state.app.content.intro,
    options: state.app.options,
  }
};

export default connect(mapStateToProps)(IntroPage);