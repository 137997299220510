import React from 'react';
import {ACTION_REDIRECT} from "../../routes/services";

const ServiceComponent = (props) => {
  const service = props.service;
  const id = `answer-${props.service.id}`;

  function onServiceShare(event) {
    event.preventDefault();

    // callback
    props.onServiceSelect(service.id, service.link, service.type, service.action);
  }

  const checked =
    props.selectedServices[service.type] === service.id
      ? true
      : props.selectedServices.hasOwnProperty(service.type) && ACTION_REDIRECT === service.action
        ? true
        : false;

  return (
    <div className={`form-field step-option step-option--${props.type}`} key={service.id}>
      <input
        onClick={onServiceShare.bind(this)}
        type="button"
        value={service.name}
        id={id} name={service.id}
      />
      <label htmlFor={id} className={`${checked ? 'checked' : ''}`}>
        {service.name}
        <i className="icon-check step-option__check"/>
      </label>
    </div>
  );
}

export default ServiceComponent;