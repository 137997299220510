import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getConfiguration, loadPreviewConfiguration} from '../actions/app';
import {bindActionCreators} from 'redux';
import Processing from './processing';
import ErrorPrompt from './errorPrompt';
import Router from './router';
import Watermark from './watermark';
import i18n from '../utils/i18n';
import {Translation} from 'react-i18next';
import * as Sentry from '@sentry/browser';
import BehaviourTracking from '../utils/behaviourTracking';
import {applyColorsFromStore} from '../utils/theme';
import {isPreview} from '../utils/preview';
import {loadPreviewQuestion} from '../actions/questionnaire';
import {getToken} from "../utils/token";

class Loader extends Component {
  constructor(props) {
    super(props);
    const hasToken = !(getToken() === null)
    this.state = {
      configurationLoaded: !hasToken,
      hasToken: hasToken,
      loading: true,
      error: false,
    };
    this.tracker = new BehaviourTracking();
    this.onConfigurationLoaded = this.onConfigurationLoaded.bind(this);
    this.onLoadError = this.onLoadError.bind(this);
  }

  static propTypes = {
    store: PropTypes.func,
    doGetConfiguration: PropTypes.func.isRequired,
    extraCssUrl: PropTypes.string,
    loadPreviewConfiguration: PropTypes.func,
    loadPreviewQuestion: PropTypes.func,
    questionnaireMeta: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.tracker.start();
    if (isPreview()) {
      const handleConfigMessage = (event) => {
        if (window !== event.source) {
          this.props.loadPreviewConfiguration(JSON.parse(event.data));
          this.props.loadPreviewQuestion();
          this.onConfigurationLoaded();
        }
      };
      window.addEventListener('message', handleConfigMessage, false);
    } else {
      if (this.state.hasToken) {
        this.props.doGetConfiguration(
          this.onConfigurationLoaded,
          this.onLoadError,
        );
      }
    }
  }

  componentWillUnmount() {
    this.tracker.stop();
  }

  onConfigurationLoaded() {
    applyColorsFromStore();
    i18n.changeLanguage(this.props.questionnaireMeta.lang).then(() => {
      this.setState({configurationLoaded: true});
    });

    document.documentElement.lang = i18n.language;
  }

  onLoadError() {
    this.setState({loading: false, error: true});
  }

  /**
   * @see https://reactjs.org/docs/error-boundaries.html
   * @param error
   * @return {{error: boolean}}
   */
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {error: !!error};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error});
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.configurationLoaded && !this.state.error) {
      return (
        <span>
          {this.props.extraCssUrl ? <link rel="stylesheet" type="text/css" href={this.props.extraCssUrl}/> : ''}
          <Router tracker={this.tracker}/>
        </span>
      );
    }
    return (
      <Translation>
        {/* eslint-disable-next-line no-unused-vars */}
        {(t, {i18n}) =>
          <div className="page template-intro">
            <div className="page__background">
              <Watermark/>
            </div>
            <div className="page__container">
              <section className="page__header"/>
              <section className="page__content">
                {this.state.error ? <ErrorPrompt
                  buttonHandler={() => {
                    this.setState({error: false});
                  }}
                /> : null}
                {this.state.loading ? <Processing/> : null}
              </section>
            </div>
          </div>
        }
      </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    extraCssUrl: state.app.extraCssUrl,
    questionnaireMeta: state.app.questionnaireMeta,
    options: state.app.options,
  };
};

const mapActionsToProps = (dispatch) => {
  const addPreview = isPreview();
  const actions = {
    doGetConfiguration: getConfiguration,
    loadPreviewConfiguration: addPreview ? loadPreviewConfiguration : null,
    loadPreviewQuestion: addPreview ? loadPreviewQuestion : null,
  };

  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(Loader);