import React, {useEffect, useState} from 'react';
import { NavLink, Redirect} from 'react-router-dom';

import Lightbox from '../components/lightbox';
import Watermark from '../components/watermark';
import {useTranslation} from 'react-i18next';
import {getBankingInfo, proceedBankingUrl} from '../utils/api';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../components/loader';

const BankingPage = (props) => {
  // States
  const [lightbox, setLightbox] = useState(null);
  const [loading, setLoading] = useState(true);
  const [context, setContext] = useState({
    'hasEmail': false,
    'status': null
  });
  const [email, setEmail] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  // i18n
  const {t} = useTranslation();

  useEffect(() => {
    setLoading(true);
    getBankingInfo().then(response => response.json()).then(({data, success}) => {
      setContext(data);

      setLoading(false);
    });
  }, []);


  const handleAuth = (e) => {
    e.preventDefault();

    setLoading(true);
    proceedBankingUrl(6, email).then(response => response.json()).then(({data, success, errors}) => {
      if (success) {
        window.location = data.url;
      } else {
        setErrorMessages(errors);

        setLoading(false);
      }
    });
  };

  const buildBody = () => {
    if (context.status === null || context === undefined) {
      return <form onSubmit={handleAuth} className="step__form">
        {!context.hasEmail ?
            <div className={`form-field step-option step-option--text`}>
              <label htmlFor="ob_email">
                Please provide your valid Email it's required for assigning data to you
                <input
                    type="text"
                    id="ob_email"
                    name="email"
                    required={context.hasEmail}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                />
              </label>

              {errorMessages.map((value) => <><small>{t('errors.' + value)}</small><br/></>)}
            </div> : null}
        <div className={`form-field step-option step-option--text`}>
          <button type="submit" className="page__next-button">{t('banking.auth')}</button>
        </div>

        <NavLink className="service__share-button" to="/questionnaire">{t('banking.skip')}</NavLink>
      </form>
    }

    if (context.status === 'failed') {
      return <form onSubmit={handleAuth}>
        <span>Look like something went wrong please try again!</span>

        {!context.hasEmail ?
            <div className={`form-field step-option step-option--text`}>
              {errorMessages.map((value) => <><small>{t('errors.' + value)}</small><br/></>)}
            </div> : null}
        <div className={`form-field step-option step-option--text`}>
          <button type="submit" className="page__next-button">{t('banking.auth')}</button>
        </div>

        <NavLink className="service__share-button" to="/questionnaire">{t('banking.skip')}</NavLink>
      </form>
    }

    return <div>
      <span>Thanks for sharing info!</span>

      <NavLink className="page__next-button" to="/questionnaire">{t('interface.start_btn')}</NavLink>
    </div>
  }

  if (props.options && (!props.options.auth_banking || props.options.open_banking_requested)) {
    return <Redirect to="/questionnaire"/>;
  }

  return (
      <>
        {loading ? <Loader/> : <div className="page template-primary">
          <div className="page__background">
            <Watermark/>
          </div>
          <div className="page__container">
            <section className="page__header">
              <h1 className="page__title">{props.content.title}</h1>
              <p className="page__text">{props.content.text}</p>
            </section>
            <section className="page__footer">
              {buildBody()}
            </section>
          </div>
          {
              !lightbox ||
              <Lightbox
                  title={lightbox.title}
                  text={lightbox.text}
                  onClose={() => {
                    setLightbox(null);
                  }}
              />
          }
        </div>}
      </>
  );
};

BankingPage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => {

  return {
    content: state.app.content.openBanking ? state.app.content.openBanking[0] : {title: '', text: ''},
    options: state.app.options,
  };
};

export default connect(mapStateToProps)(BankingPage);